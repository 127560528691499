import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import oval1 from "@images/oval.svg"
import oval2 from "@images/oval-2.svg"
import oval3 from "@images/oval-3.svg"

// Inline styling

const termsContent = {
  padding: "10rem 0 5rem 0",
  position: "relative",
  overflow: "hidden",
}

const heroHeader = {
  fontSize: 40,
  marginBottom: "2rem",
}

// end of styling

const Recruitment = ({location}) => {
  return (
    <>
      <Layout location={location}>
        <SEO title="Recruitment" description="" />

        <div className="innerpages-hero">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="hero-text-wrapper text-center">
                  <h1 style={heroHeader}>How To Make $10k-$20k Per Month As A Salesperson With Leadroll.co</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="terms-content" style={termsContent}>
          <img className="oval-svg" src={oval1} alt="Oval svg" />
          <img className="oval-2" src={oval2} alt="Oval svg" />
          <img className="oval-3" src={oval3} alt="Oval svg" />
          <div className="container">
            <div className="row">
              <div className="col-10 m-auto">
              <ol className="h3 mb-4">
                <li><h3>Please watch the full video.</h3></li>
                <li><h3>Click "Apply Now" to submit an application for an introductory call.</h3></li>
              </ol>
              <div className="embed-container shadow-lg-dark">
                <iframe
                  src="https://player.vimeo.com/video/308469803"
                  title="VSL"
                  frameBorder="0"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen
                  width="100%"
                />
              </div>
                <div className="text-center p-3 offer-cta mt-5">
                  <Link to="/quiz" className="btn btn-primary btn-lg btn-red">
                    Apply Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Recruitment
